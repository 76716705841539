.dropdown {

    select {
        background: transparent;
        color: white;
        border-radius: 3px;
        border: 1px solid rgba(255,255,255,0.5);
        padding: 4px 5px;
    }
}
