.timer {
    width: 100%;
    height: 5px;
    background-color: #D8FAFF;
    margin-bottom: 5px;
    border-radius: 10px;

    &__inner {
        height: 5px;
        width: 100%;
        transition: width 0.3s ease;
        background-color: #267B88;
        border-radius: 10px;
    }

    &.med .timer__inner {
        background-color: #e8b45a;
    }

    &.low .timer__inner {
        background-color: #F42F53;
    }
}
