.scoreboard {
    width: 100%;
    text-align: center;
    background-color: #fff;
    padding: 0 0 15px 0;

    .figure {
        font-size: 23px;
        display: block;
        margin: 0;
        color: white;
    }

    .score {
        display: inline-block;
        padding: 3px 25px;
        text-align: center;
        border-radius: 12px;
        margin: 0 5px;
        margin-top: -10px;
        padding-top: 9px;

        &.correct {
            background-color: #33B26A;
        }

        &.wrong {
            background-color: #F42F53;
        }
    }
}
