.location {
    background-color: #267B88;
    border-radius: 30px;
    padding: 5px 8px;
    margin-bottom: 35px;
    color: white;
    cursor: pointer;
    transition: filter .2s;

    &:hover {
        filter: brightness(110%);
    }

    &.is-selected {
        background-color: #e8b45a;
    }

    &.is-wrong {
        background-color: #F42F53;
    }

    &.is-correct {
        background-color: #33B26A;
    }

    &__title {
        margin: 0;
        font-size: 26px;
        font-weight: 100;
        line-height: 1.1;
        display: flex;
        align-items: center;
    }

    &__country {
        display: inline-block;
        font-size: 19px;
        font-weight: 600;
        line-height: 1.1;
    }

    &__dist {
        font-size: 17px;
        font-weight: 600;
        margin-left: 8px;
        top: 2px;
        position: relative;
    }

    &__flag {
        margin-right: 10px;
        display: block;
        float: left;
        position: relative;
        top: 3px;
        width: 50px;
        height: 50px;
        object-fit: cover;
        object-position: center;
        border-radius: 50%;
    }
}
