.menu {
    &--splash {
        background-color: #267B88;
        padding: 5rem 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: 100vh;

        h1 {
            color: white;
            text-shadow: 0 1px 1px #23494e;
            font-weight: 500;
            font-size: 41px;
            margin-bottom: 45px;
        }
    }

    &--settings {
        min-height: 100vh;
        background-color: #267B88;
        padding: 5rem 0;

        h1 {
            color: white;
            text-shadow: 0 1px 1px #23494e;
            font-weight: 500;
            font-size: 41px;
            margin-bottom: 15px
        }
    }

    .form-item {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 15px;

        &__label {
            color: white;
            text-shadow: 0 1px 1px #23494e;
            font-weight: 500;
            margin-bottom: 0;
        }
    }
}

.btn {
    border: 0;
    appearance: none;
    padding: 11px 20px;
    background-color: #23494e;
    color: white;
    font-weight: 600;
    font-size: 19px;
    line-height: 1.3;
    cursor: pointer;
    margin-bottom: 25px;
    min-width: 9rem;
}
