$bgColor: #f1f1f1;

@import '~bootstrap/scss/bootstrap.scss';

body {
    background-color: #f1f1f1;
}


.question__wrapper {
    background-color: white;
    padding: 10px 0;
    margin-bottom: 15px;

    .location {
        background-color: white;
        color: black;
        padding: 0;
        margin-bottom: 15px;
        border: 0
    }
}

.question__heading {
    text-align: center;
    font-size: 26px;
    margin-bottom: 15px;
    font-weight: 400;
}
